.ParallaxTop {
    width: 100vw;
    min-height: 550px;
    position: relative;
    perspective: 0px;
    transform-style: preserve-3d;
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    text-align: center;
    z-index: -2;
    scroll-behavior: smooth;
    &_img {
        position: absolute;
        left: -25px;
        right: 0;
        bottom: 0;
        top: 700px;
        filter: brightness(0.6);
    }

    &_img::before {
        width: 100%;
        height: 100%;
        background-size: cover;
        content: '';
        display: block;
        z-index: -1;
        transform-origin: center;
        min-height: 100vh;
        background-size: cover;
        transition: 1s;
    }
}
.static{
    display: none;
}

@media(max-width:986px){
    .ParallaxTop_img{
        top: 929px;
    }
}
@media(max-width:850px){
    .ParallaxTop_img{
        top: 1050px;
    }
}
@media(max-width:501px){
    .ParallaxTop_img{
        top: 1250px;
    }
}