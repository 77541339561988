
:root {
    --hue: 223;
    --bg: hsl(var(--hue), 90%, 90%);
    --fg: hsl(var(--hue), 90%, 10%);
    --primary: hsl(var(--hue), 90%, 50%);
    --trans-dur: 0.3s;
    font-size: calc(16px + (24 - 16) * (100vw - 320px) / (2560 - 320));
}

.loading__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 4;
}

.pl1 {
    position: absolute;
    right: 50%;
    transform: translate(50%, -50%);
    top: 50%;
    display: block;
    width: 8em;
    height: 8em;
}

.pl1__g,
.pl1__rect {
    animation: pl1-a 1.5s cubic-bezier(0.65, 0, 0.35, 1) infinite;
}

.pl1__g {
    transform-origin: 64px 64px;
}

.pl1__rect:first-child {
    animation-name: pl1-b;
}

.pl1__rect:nth-child(2) {
    animation-name: pl1-c;
}


/* Dark theme */
@media (prefers-color-scheme: dark) {
    :root {
        --bg: hsl(var(--hue), 90%, 10%);
        --fg: hsl(var(--hue), 90%, 90%);
    }
}

/* Animations */
@keyframes pl1-a {
    from {
        transform: rotate(0);
    }

    80%,
    to {
        animation-timing-function: steps(1, start);
        transform: rotate(90deg);
    }
}

@keyframes pl1-b {
    from {
        animation-timing-function: cubic-bezier(0.33, 0, 0.67, 0);
        width: 40px;
        height: 40px;
    }

    20% {
        animation-timing-function: steps(1, start);
        width: 40px;
        height: 0;
    }

    60% {
        animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
        width: 0;
        height: 40px;
    }

    80%,
    to {
        width: 40px;
        height: 40px;
    }
}

@keyframes pl1-c {
    from {
        animation-timing-function: cubic-bezier(0.33, 0, 0.67, 0);
        width: 40px;
        height: 40px;
        transform: translate(0, 48px);
    }

    20% {
        animation-timing-function: cubic-bezier(0.33, 1, 0.67, 1);
        width: 40px;
        height: 88px;
        transform: translate(0, 0);
    }

    40% {
        animation-timing-function: cubic-bezier(0.33, 0, 0.67, 0);
        width: 40px;
        height: 40px;
        transform: translate(0, 0);
    }

    60% {
        animation-timing-function: cubic-bezier(0.33, 1, 0.67, 1);
        width: 88px;
        height: 40px;
        transform: translate(0, 0);
    }

    80%,
    to {
        width: 40px;
        height: 40px;
        transform: translate(48px, 0);
    }
}
