.MenuFirstScreen {
    padding-top: 80px;
    position: relative;
    height: 80vh;
}

.MenuFirstScreen_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    @media (max-width:768px) {
        grid-template-columns: 1fr;

    }
}

.MenuFirstScreen_bg {
    img {
        width: 100%;
        height: 100%;
    }
}

.Tabllist {
    margin-top: 100px;
    font-size: 16px;
    font-weight: normal;
    color: var(--color-grey);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 30px 0px;
    row-gap: 10px;
    z-index: 2;
    position: relative;

    @media (max-width:768px) {
        max-width: 500px;
        margin: 50px auto 0;
    }

}

.Tablist_item {
    cursor: pointer;
    padding: 10px;
    box-sizing: border-box;
    transition: color 0.3s ease-in-out;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--color-grey);
    text-shadow: 1px 1px 1px white;

    &:hover {
        color: var(--color-white);
    }

}

.Tablist_item_active {
    color: var(--color-white);
}