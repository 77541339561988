.Header {
    position: fixed;
    width: 100%;
    z-index: 5;
    transition: background 1s ease-in-out;

    @media(min-width: 850px) {
        background-color: transparent;
        color: white;

    }
}

.scroll {
    background-color: white !important;
    color: rgba(0, 0, 0, .7) !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .09);

}


.menu {
    background-color: rgba(0, 0, 0, 0.10);
}

.Header_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    position: relative;
    height: 50px;

    @media(min-width: 850px) {
        height: 80px;

    }
}

.BtnClose {
    width: 28px;
    height: 20px;
    position: relative;
    z-index: 5;
    border: none;
    background: transparent;
    cursor: pointer;
    transition: all 1s ease-in-out;

    &:hover {

        span,
        span::after,
        span::before {
            background-color: var(--color-red);
        }
    }

    span,
    span::after,
    span::before {
        transition: all 0.5s ease-in-out;
        content: '';
        width: 100%;
        background-color: var(--color-grey);
        border-radius: 10px;
        height: 3.5px;
        display: block;
        left: 0;
        position: absolute;


    }

    span {
        top: 0;
    }

    span::after {
        top: 10px;
    }

    span::before {
        top: 20px;
    }

    @media(min-width: 850px) {
        display: none;
    }
}

._active_btn {
    span {
        transform: rotate(45deg);
        top: 10px;
    }

    span::after {
        opacity: 0;
        top: 10px;
        width: 0%;
        left: 50%;
    }

    span::before {
        transform: rotate(90deg);
        top: 0;
    }
}



.Navbar {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.9);
    transition: all 0.6s ease-in-out;
    z-index: 4;
    top: 0;
    left: -200px;
    width: 0;
    height: 0;
    overflow: hidden;
    padding: 0;
    a{
        cursor: pointer;
    }
    ul {
        padding: 0 30px;

        li {
            margin-bottom: 0px;
            transition: all 1s ease-in-out;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: normal;

            &:hover {
                letter-spacing: 4px;
            }
        }
    }

    @media(min-width: 850px) {
        left: auto;
        background-color: transparent;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1 0 auto;
        width: auto;
        height: auto;
        max-width: 600px;

        .activeLink {
            color: var(--color-red);

            &::before {
                content: '';
                position: absolute;
                height: 2px;
                background-color: var(--color-red);
                width: 100%;
                bottom: -1px;
                transition: width 0.3s ease-in;
            }
        }

        ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1 0 auto;
            gap: 10px;

            a {
                padding: 20px 0;
                transition: color 0.1s ease-in;
                color: inherit;

                &:hover {
                    color: var(--color-red);
                }
            }

            li {
                font-size: 15px;
                font-weight: 400;
                margin-bottom: 0px;
                color: inherit;
                position: relative;
                letter-spacing: 2px;

                &::before {
                    content: '';
                    position: absolute;
                    height: 2px;
                    background-color: var(--color-red);
                    width: 0;
                    bottom: -1px;
                    transition: width 0.3s ease-in;
                }

                &:hover {
                    letter-spacing: 2px;

                    &::before {
                        width: 100%;
                    }
                }
            }

        }
    }
}

._active_nav {
    width: 100%;
    height: 100%;
    padding: 45px 25px 0;
    left: 0;

    ul {
        li {
            margin-bottom: 20px;
            letter-spacing: 4px;
        }
    }
}

.header-btn {
    display: none;

    @media(min-width: 1139px) {
        display: block;
        font-weight: 400;
        height: 40px;
        padding: 4px 0px;
        width: 255px;
    }
}

.icon {
    @media(min-width: 850px) {
        display: none;
    }
}

.Header_logo {
    cursor: pointer;
    svg {
        g {

            fill: white;
        }
    }
    &:hover {
        svg {
            g {

                fill: var(--color-red);
            }
        }
    }
}

.scroll_logo {
    svg {
        g {

            fill: black;
        }
    }
}