.Slider {
    padding: 50px 0px;

    @media (max-width: 768px) {
        padding: 25px 0px;
    }
}

.Slider_content {
    position: relative;
}


.Slider_title {
    text-align: center;

    h3 {
        color: #FF1A1E;
        font-size: 60px;
        letter-spacing: 1px;
        line-height: 1.2;
        font-weight: 400;

        @media (max-width: 768px) {
            font-size: 53px;
        }
    }

    p {
        text-transform: uppercase;
        color: var(--color-white);
        margin: 0;
        padding: 0;
        letter-spacing: .05em;
        font-size: 50px;
        position: relative;
        font-weight: 700;
        line-height: 40px;

        @media (max-width: 768px) {
            font-size: 27px;
        }

    }
}

.Slider_btn_container {
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translate(-50%, -50%);
    z-index: 14;
    width: 200px;

    @media (max-width: 768px) {
        bottom: 20px;
    }
    @media (max-width: 400px) {
    }
}


@media (max-width: 768px) {
.Slider_title{
    margin: 0 0 -50px 0;
}
}