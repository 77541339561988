.Info_box {
    display: flex;
    justify-content: space-around;
    margin-top: 100px;
    flex-wrap: wrap;

}

.Info {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 430px;

    &_logo {
        max-width: 60%;
        margin: -15px auto 35px auto
    }

    &_separation {
        color: var(--color-grey);
        line-height: 24px;
        font-size: 15px;
        margin-bottom: 16px;
        text-align: center;
    }

    &_text {
        color: var(--color-grey);
        line-height: 24px;
        font-size: 15px;
        margin-bottom: 30px;
        text-align: justify;
    }

    &_reservation {
        position: relative;
        color: var(--color-black);
        font-size: 15px;
        padding-bottom: 5px;
        cursor: pointer;
        position: relative;
        margin: 0 auto;
        transition: color 600ms ease-in-out;


        &::after,
        &::before {
            position: absolute;
            content: '';
            height: 2px;
            background: black;
            width: 100%;
            bottom: 0;
            left: 0;
            transition: width 500ms ease-in-out;

        }

        &::before {
            width: 0;
            background-color: var(--color-deep-red);
            z-index: 1;
        }

        &:hover {
            color: var(--color-deep-red);

            &::before {
                width: 100%;
            }
        }

    }
}


.img {
    max-width: 526px;
    max-height: 645px;

    &_item {
        width: 100%;
        height: 100%;
        background-size: cover;
    }
}

@media(max-width:985px) {
    .Info {
        display: flex;
        flex-direction: column;
        text-align: center;
        max-width: 100%;

        &_text {
            color: var(--color-grey);
            line-height: 24px;
            font-size: 15px;
            margin-bottom: 30px;
            text-align: justify;
        }
    }

    .img {
        margin: 37px 0 0 0;
    }
}