.Modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
    opacity: 0;
    pointer-events: none;


}

.overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;

    // ::-webkit-scrollbar {
    //     width: 10px;
    //     height: 8px;
    //     background-color: #d6d6d6;
    // }

    // ::-webkit-scrollbar-thumb {
    //     background-color: #878787;
    //     border-radius: 9em;
    // }

    // ::-webkit-scrollbar-thumb:hover {
    //     background-color: #253861;
    // }
}

.content {
    max-height: 100vh;
    overflow: auto;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  

    @media (min-width: 750px) {
        border-radius: 12px;
    }

    background: var(--bg-color);
    transition: 0.3s transform;
    transform: scale(0.5);
}

.opened {
    pointer-events: auto;
    opacity: 1;

    .content {
        transform: scale(1);
    }
}

.isClosing {
    .content {
        transform: scale(0.2);
    }
}
.content_close_btn{
    right: 10px;
    top: 20px;
    position: absolute;
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    display: block;
    cursor: pointer;
    &:hover{
        &::after,
        &::before{
            background-color:var(--color-red);
        }
    }
    &::after,
    &::before{
        width: 100%;
        content: '';
        background-color: var(--color-grey);
        height: 3px;
        position: absolute;
        z-index: 10;
        border-radius: 3px;
        transition: all 0.3s ease-in-out;
        
    }
    &::before{
        transform: rotate(45deg);
    }
    &::after{
        transform: rotate(-45deg);
    }
}