.Button {
    text-transform: uppercase;
    color: white;
    background: var(--color-red);
    border: 1px solid var(--color-white);
    font-size: 14px;
    line-height: 1.5;
    border-radius: 30px;
    padding: 10px 30px;
    z-index: 12;
    transition: ease 0.5s;
    min-width: 70px;
    box-sizing: border-box;
    &:hover{
        color: var(--color-red);
        border: 1px solid var(--color-red);
        background:var(--color-white);
    }
    &:disabled{
        background:var(--color-grey);

    }
}
@media(max-width:400px){
    .Button {
     
    } 
}