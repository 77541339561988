.RenderMenu{
    color: black;
    background-color: #fef9f4;
    padding-bottom: 50px;
    padding-top: 50px;
}

.Render_item {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;

    span:first-child {
        margin-right: 5px;
    }
}

.Render_category_title {
    font-size: 28px;
    letter-spacing: 2px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
}

.Render_category_subtitle {
    padding: 20px 0;
    font-size: 24px;
    letter-spacing: 2.5px;
    text-align: center;
    position: relative;

    @media (max-width: 768px) {
        font-size: 20px;
    }

    &::before {
        content: '';
        height: 3px;
        background-color: black;
        position: absolute;
        left: 0;
        bottom: 28px;
        right: 0;
        z-index: 0;
        border-radius: 5px;
    }

    span {
        z-index: 2;
        background-color: #fef9f4;
        padding: 10px;
        position: relative;
    }


}

.Render_item_title {
    font-size: 18px;
    padding: 5px 0;

    @media (max-width: 768px) {
        font-size: 16px;
    }
}

.Render_item_text {
    color: var(--color-grey);
}

.Render_item_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    font: var(--font-l);



    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }

}

.Render_item_price {
    color: black;
    font-weight: 500;
    display: flex;

    p {
        padding: 0 3px;
    }
}