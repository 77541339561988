.Form {
    margin: 0 auto;
    background-color: white;
    padding: 30px 20px 20px;
    border-radius: 15px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .09);

}

.Form_list_container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;

    button {
        width: 200px !important;
    }
}

.Form_list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;

    @media (max-width:590px) {
        grid-template-columns: 1fr;
    }

    &>:nth-child(3) {
        @media (min-width:590px) {
            grid-area: 2 / 1 / 3 / 3;
        }
    }

}

.Form_list_field {
    position: relative;
}

.Form_list_field_erorr {
    position: absolute;
    border: -5px;
    font-size: 12px;
    color: var(--color-red);
}

.Form_list_input {
    height: 40px;
    border-radius: 3px;
    padding: 8px 16px;
    outline: 1px solid var(--color-grey);
    border: none;
    position: relative;
    width: 100%;

    &:focus {
        outline: 1px solid var(--color-red);
    }
}

.send_container {
    padding: 35px;
    background-color: white;
    border-radius: 10px;
}