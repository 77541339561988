@import './reset.scss';
@import './variables.scss';

html {
}


body {
  font: var(--font-m);
  color: var(--color-grey);
  margin: 0;
}



.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}


.page-wrapper {
  flex-grow: 1;
}

.content {
  max-width: 1200px;
  min-width: 350px;
  margin: 0 auto;
  padding: 0 30px;

  @media (max-width: 700px) {
    padding: 0 5px;
  }
}

.modal-show {
  overflow: hidden;
  height: 100vh;
  @media (max-width:845px) {
    padding-right: 15px;
  }
}