.swiper_container_menu {
    width: 100% ;
    max-width: 1100px;
    
}
.menuSwiper {

    .swiper {
        height: 100%;
        padding: 0 25px;
    }

    .swiper-slide {
        color: var(--color-grey);
        font-size: 16px;
        word-wrap: break-word;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px 5px;
    }


    .swiper {
        margin-left: auto;
        margin-right: auto;
    }


    .swiper-button-next::after,
    .swiper-button-prev::after {
        color: red !important;
        display: block !important;
        z-index: 10;
        position: relative;
        @media (max-width:768px) {}
    }


    .swiper-button-prev {
        padding: 10px;
        left: 0px !important;
        background-color: var(--color-beige) !important;
        z-index: 10;
    }
    .swiper-button-disabled{
        opacity: 0.1;
    }
    .swiper-button-next {
        padding: 10px;
        right: 0px !important;
        background-color: var(--color-beige) !important;
        z-index: 10;
    }

    @media (max-width:768px) {
        .swiper-button-next,
        .swiper-button-prev{
            display: none;
        }
    }
}