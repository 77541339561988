.Providing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 36px 0;
    flex-wrap: wrap;
}

.box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;

}

.box_reverse {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
}


.advantages {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 0 30px 0;

    &_text {
        font-size: 25px;
        line-height: 3.528571;
        margin: 15px 0 0;
        text-transform: uppercase;
        color: #000000;
    }

    &_img {
        height: 85px;
        width: 104px
    }
}

.marginRight {
    margin-right: 100px;
}

.marginLeft {
    margin-left: 100px;
}

.location {
    justify-content: unset;
}

.hidden {
    display: none;
}


.box_reverse .advantages:nth-child(1) .advantages_text {
    margin: 0px 13px 0 0px

}

@media(max-width:1232px) {
    .marginRight {
        margin-right: 50px;
    }

    .marginLeft {
        margin-left: 50px;
    }
}

@media(max-width:1057px) {
    .marginRight {
        margin-right: 20px;
    }

    .marginLeft {
        margin-left: 20px;
    }

    .advantages {

        margin: 0px 0 30px 0;

        &_text {
            font-size: 16px;
            line-height: 3.528571;
            margin: 15px 0 0;
            text-transform: uppercase;
            color: #000000;
        }

        &_img {
            height: 85px;
            width: 104px
        }
    }
}

@media(max-width:700px) {
    .Providing {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 70px 0;
        flex-wrap: wrap;
    }

    .visible {
        display: none;
    }

    .hidden {
        display: flex;
    }

    .location {
        justify-content: space-between;
    }

}

@media(max-width:440px) {

    .advantages_text {
        font-size: 13px;
    }
}