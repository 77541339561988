.ParallaxSlider {
    width: 100vw;
    min-height: 550px;
    position: relative;
    perspective: 0px;
    transform-style: preserve-3d;
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    text-align: center;
    z-index: -2;
    scroll-behavior: smooth;

    &_img {
        position: absolute;
        left: -25px; 
        right: 0;
        bottom: 0;
        top: 2300px;
        filter: brightness(0.5);
    }

    &_img::before {
        width: 100%;
        height: 100%;
        background-size: cover;
        content: '';
        display: block;
        z-index: -1;
        transform-origin: center;
        min-height: 100vh;
        background-size: cover;
        transition: 1s;
    }
}

.static {
    display: none;
}



@media(max-width:1201px) {
    .ParallaxSlider_img {
        top: 3300px;
    }
}
@media(max-width:975px) {
    .ParallaxSlider_img {
        top: 3500px;
    }
}
@media(max-width:938px) {
    .ParallaxSlider_img {
        top: 4800px;
    }
}
@media(max-width:721px) {
    .ParallaxSlider_img {
        top: 5500px;
    }
}
@media(max-width:450px) {
    .ParallaxSlider_img {
        top: 5300px;
    }
}
@media(max-width:380px) {
    .ParallaxSlider_img {
        top: 5200px;
    }
}




