:root {
    --font-family-main: "Open Sans", sans-serif;
    --font-size-m: 15px;
    --font-line-m: 24px;
    --font-m: var(--font-size-m) / var(--font-line-m) var(--font-family-main);
    --font-size-l: 18px;
    --font-line-l: 22px;
    --font-l: var(--font-size-l) / var(--font-line-l) var(--font-family-main);
    --font-size-xl: 36px;
    --font-line-xl: 36px;
    --font-xl: var(--font-size-xl) / var(--font-line-xl) var(--font-family-main);



    //text-color
    --color-grey: #8b8e94;
    --color-black: #000000;
    --color-white: #fffefe;
    --color-red: #FF1A1E;
    --color-deep-red: #ff0033;
    --color-grey-deep: rgba(255, 255, 255, .7);
    --color-beige :    #fef9f4;


}