.Slider {
    background-color: var(--color-beige);
    width: 100%;
    overflow: hidden;
    top: 80px;
    transition: all 0.6s ease-in-out;
    z-index: 3;
    position: sticky !important;
    @media (max-width: 845px) {
        top:50px;
    }
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.29);

}



.Slider_content {
    position: relative;

}

.Tablist_item {
    cursor: pointer;
    box-sizing: border-box;
    transition: color 0.3s ease-in-out;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--color-grey) ;
    text-shadow: 1px 1px 1px gray ;
    text-align: center;

    @media (hover: hover) {
        color: var(--color-white);
    }


}

.Tablist_item_active {
    color: var(--color-black);
}