* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

input,
button,
textarea,
select {
    margin: 0;
    font: inherit;
}

a {
    color: black;
    text-decoration: none;
}

li {
    list-style-type: none;
}

button {
    border: none;
    background-color: transparent;
    cursor: pointer;
}