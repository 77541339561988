.PresentationMenu {
    height: 847px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    top: -58px;
    justify-content: center;
}

.title {
    color: var(--color-white);
    top: -231px;
    left: 50%;
    transform: translate(-50%, 50%);
    position: absolute;
    text-align: center;

    &_top {
        color: var(--color-red);
        font-size: 60px;
    }

    &_bottom {
        font-size: 50px;
        line-height: 40px;
        padding: 31px 0 0 0
    }
}

@media(max-width:1201px) {
    .PresentationMenu {
        height: auto;
    }
}
// @media(max-width:1200px) {
//     .PresentationMenu {
//         height: auto;
//     }
// }


@media(max-width:518px) {
    .title {
        &_top {
            color: var(--color-red);
            font-size: 46px;
        }
    
        &_bottom {
            font-size: 39px;
            line-height: 14px;
            padding: 31px 0 0 0
        }
    }

}
@media(max-width:500px) {
    .PresentationMenu {
        position: relative;
        top: 0;
    }
}
@media(max-width:382px) {
    .title {
        &_top {
            font-size: 46px;
        }
    
        &_bottom {
            font-size: 29px;
            line-height: 14px;
            padding: 31px 0 0 0
        }
    }

}