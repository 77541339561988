.container {
    max-width: 1230px;
    padding: 0 15px;
    margin: 0 auto;
    box-sizing: border-box;
}

.containerPresentationMenu {
    max-width: 1230px;
    padding: 0 15px;
    margin: 0 auto;
    box-sizing: border-box
}

.backgroung {
    background-color: #E4E4E4;
    max-height: auto;
}

.chefBack {
    background-color: var(--color-white);
}

.sliderBox {
    position: relative;

    &_slider {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 1230px;
        padding: 0 15px;
        margin: 0 auto;
        box-sizing: border-box;
        transform: translate(-50%, -50%);
    }
}

@media(max-width:709px) {
    .containerPresentationMenu {
        padding: 0 0px;
    }

    .backgroung {
        background-color: var(--color-white);
    }
}

.MainPage_fs {
    height: 580px;
    margin-bottom: 50px;
}
.MainPage{
    overflow: hidden;

}