.ParallaxBottom {
    width: 100vw;
    min-height: 550px;
    position: relative;
    perspective: 0px;
    transform-style: preserve-3d;
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    text-align: center;
    z-index: -2;
    scroll-behavior: smooth;


    &_img {
        position: absolute;
        left: -40px;
        right: 0;
        bottom: 0;
        top: 2000px;
        filter: brightness(0.8);
    }

    &_img::before {
        width: 100%;
        height: 100%;
        background-size: cover;
        content: '';
        display: block;
        z-index: -1;
        transform-origin: center;
        min-height: 100vh;
        background-size: cover;
        transition: 1s;
    }
}

.static {
    display: none;
}

.title {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: center;
&_box{
    max-width: 370px;
    min-width: 340px;
}
    &_item {
        font-size: 50px;
        letter-spacing: 1px;
        color: red
    }

    &_bottom {
        font-size: 50px;
        letter-spacing: 1px;
        color: rgb(255, 255, 255);
        margin: 42px 0 0 0;
    }
}

@media(max-width:1201px) {
    .ParallaxBottom_img {
        top: 2600px;
    }
}
@media(max-width:938px) {
    .ParallaxBottom_img {
        top: 4600px;
    }
}
@media(max-width:354px) {
    .ParallaxBottom_img {
        top: 4100px;
    }
}




@media(max-width:757px) {
    .title {

        &_item {
            font-size: 38px;
            letter-spacing: 1px;
            color: var(--color-red)
        }

        &_bottom {
            font-size: 38px;
            letter-spacing: 1px;
            color: var(--color-white);
            margin:19px 0 0 0;
        }
    }
}
